exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fellowships-js": () => import("./../../../src/pages/fellowships.js" /* webpackChunkName: "component---src-pages-fellowships-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internships-js": () => import("./../../../src/pages/internships.js" /* webpackChunkName: "component---src-pages-internships-js" */),
  "component---src-pages-opportunities-js": () => import("./../../../src/pages/opportunities.js" /* webpackChunkName: "component---src-pages-opportunities-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-people-past-js": () => import("./../../../src/pages/people-past.js" /* webpackChunkName: "component---src-pages-people-past-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-writing-group-js": () => import("./../../../src/pages/writing-group.js" /* webpackChunkName: "component---src-pages-writing-group-js" */),
  "component---src-templates-dialogue-index-js": () => import("./../../../src/templates/dialogue-index.js" /* webpackChunkName: "component---src-templates-dialogue-index-js" */),
  "component---src-templates-dialogue-js": () => import("./../../../src/templates/dialogue.js" /* webpackChunkName: "component---src-templates-dialogue-js" */),
  "component---src-templates-event-index-js": () => import("./../../../src/templates/event-index.js" /* webpackChunkName: "component---src-templates-event-index-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post-index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-research-index-js": () => import("./../../../src/templates/research-index.js" /* webpackChunkName: "component---src-templates-research-index-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */)
}

